import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import {
  Box,
  Container,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { yellow } from "@mui/material/colors";
import ProjectCreate from "./forms/ProjectCreate";

const AppIndex = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState(['iní', 'cio']);

  const [createProjectOpen, setCreateProjectOpen] = useState(false);

  const projects = [
    {
      id: 1,
      title: "Casa Realengo",
      client: "José Guilherme",
      status: "Em Andamento",
    },
    {
      id: 2,
      title: "Apto Copacabana",
      client: "Ricardo Valença",
      status: "Finalizado",
    },
  ];
  return (
    <Box>
      <PageTitle title='início' />
      <Container maxWidth="xl">
        <Grid
          container
          bgColor="background.dark"
          sx={{
            height: "100%",
            mt: 2,
            display: "flex",
            gap: 2,
          }}
        >
          <Grid
            item
            xs={12}
            md={3.9}
            component={Paper}
            bgcolor="background.dark"
            p={1}
            sx={{ height: { xs: "6vh", md: "65vh" } }}
          >
            <ProjectCreate open={createProjectOpen} setOpen={setCreateProjectOpen} />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" color="fonts.primary">
                projetos
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  color: "fonts.primary",
                }}
              >
                <IconButton
                  sx={{
                    bgcolor: "primary.main",
                    color: "rgba(255, 255, 255, 0.45)",
                    // fontWeight: 600,
                  }}
                  onClick={() => setCreateProjectOpen(true)}
                >
                  <Add />
                </IconButton>
                <IconButton
                  sx={{
                    bgcolor: "primary.main",
                    color: "rgba(255, 255, 255, 0.45)",
                    // fontWeight: 600,
                  }}
                >
                  <Search />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                width: "100%",
                height: "100%",
                overflow: "auto",
                maxHeight: "60vh",
                pr: 1,
              }}
            >
              <MenuList dense="dense">
                {projects.map((project) => (
                  <Box
                    key={project.id}
                    bgcolor="background.dark"
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      color: "fonts.primary",
                      mb: 1,
                    }}
                  >
                    <MenuItem
                      onClick={() => navigate(`/portal/projetos/${project.id}`)}
                      active
                    >
                      <ListItemText>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Box
                            sx={{
                              bgcolor:
                                project.status === "Finalizado"
                                  ? "rgba(0,0,0,0,0)"
                                  : yellow[800],
                              width: 15,
                              height: 15,
                              borderRadius: "50%",
                            }}
                          />
                          <Typography variant="subtitle1">
                            {project.title} - {project.client}
                          </Typography>
                        </Box>
                      </ListItemText>
                    </MenuItem>
                  </Box>
                ))}
              </MenuList>
            </Box>
          </Grid>
          <Outlet />
        </Grid>
      </Container>
    </Box>
  );
};

export default AppIndex;
