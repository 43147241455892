import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import Clients from "../Clients";

const Home = () => {

  return (
    <>
      <Clients />
      <Grid
        item
        xs={12}
        md={3.9}
        component={Paper}
        bgcolor="background.dark"
        p={1}
        sx={{ height: { xs: "6vh", md: "65vh" } }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" color="fonts.primary">
            tarefas
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "center",
              color: "fonts.primary",
            }}
          >
            <IconButton
              sx={{
                bgcolor: "primary.main",
                color: "rgba(255, 255, 255, 0.45)",
                // fontWeight: 600,
              }}
            >
              <Add />
            </IconButton>
            <IconButton
              sx={{
                bgcolor: "primary.main",
                color: "rgba(255, 255, 255, 0.45)",
                // fontWeight: 600,
              }}
            >
              <Search />
            </IconButton>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Home;
