import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Box, CircularProgress } from "@mui/material";

import image1 from "../../../assets/images/image1.webp";
import image2 from "../../../assets/images/image2.webp";

const HomeHeader = () => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Image Box */}
      <Box sx={{ width: "100%", height: "100%", zIndex: 1 }}>
        <Carousel indicators={false}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              minHeight: "80vh",
              zIndex: 99,
            }}
          >
            <img
              src={image1}
              style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: 99,
              }}
              loading="lazy"
              onLoad={() => setLoaded(true)}
              alt=""
            />

            <Box
              sx={{
                width: "100%",
                height: "100%",
                minHeight: "80vh",
                display: loaded ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress loading={true} />
            </Box>
          </Box>

          
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              minHeight: "80vh",
              zIndex: 99,
            }}
          >
            <img
              src={image2}
              style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: 99,
              }}
              loading="lazy"
              onLoad={() => setLoaded(true)}
              alt=""
            />

            <Box
              sx={{
                width: "100%",
                height: "100%",
                minHeight: "80vh",
                display: loaded ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress loading={true} />
            </Box>
          </Box>
        </Carousel>
      </Box>
    </Box>
  );
};

export default HomeHeader;
