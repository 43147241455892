import React from "react";
import {  Box, Container, Typography } from "@mui/material";

const PageTitle = ({ title, description }) => {
  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ display: "flex", width: "100%", mt:2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "row" },
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" fontWeight={400} color="fonts.primary">
                {title}
              </Typography>
            </Box>
          </Box>

          
        </Box>

        {description && (
          <Box>
            <Typography
              variant="h6"
              sx={{ color: "fonts.primary" }}
            >
              {description}
            </Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default PageTitle;
