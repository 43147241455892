export const projectList = [
  {
    title: "Apartamento GR",
    images: [
      { path: "grudes/projeto_gr_1.webp" },
      { path: "grudes/projeto_gr_2.webp" },
      { path: "grudes/projeto_gr_3.webp" },
      { path: "grudes/projeto_gr_4.webp" },
      { path: "grudes/projeto_gr_5.webp" },
    ],
  },
  {
    title: 'Clinica Camila Raeder',
    images: [
        { path: "camila/projeto_camila_raeder_1.webp"},
        { path: "camila/projeto_camila_raeder_2.webp"},
        { path: "camila/projeto_camila_raeder_3.webp"},
        { path: "camila/projeto_camila_raeder_4.webp"},
        { path: "camila/projeto_camila_raeder_5.webp"},
    ]
  },
  {
    title: 'Escritório Castilho',
    images: [
        { path: "cintia/projeto_castilho_adv_1.webp"},
        { path: "cintia/projeto_castilho_adv_2.webp"},
        { path: "cintia/projeto_castilho_adv_3.webp"},
        { path: "cintia/projeto_castilho_adv_4.webp"},
        { path: "cintia/projeto_castilho_adv_5.webp"},
    ]
  },
  {
    title: 'Quarto Infantil - RU',
    images: [
        { path: "cobertura/projeto_cobertura_infantil_1.webp"},
        { path: "cobertura/projeto_cobertura_infantil_2.webp"},
        { path: "cobertura/projeto_cobertura_infantil_3.webp"},
        { path: "cobertura/projeto_cobertura_infantil_4.webp"},
        { path: "cobertura/projeto_cobertura_infantil_5.webp"},
    ]
  }
];
