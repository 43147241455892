import React, { useEffect, useState } from "react";

import HomeHeader from "./sections/Header";
import Portfolio from "./sections/Portfolio";
import HomeContact from "./sections/Contact";

const HomeIndex = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleOpen = () => setMenuOpen(!menuOpen);

  
  useEffect(()=>{
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
  }, [])
  return (
    <>
      <HomeHeader menuOpen={menuOpen} handleOpen={handleOpen}/>
      <Portfolio menuOpen={menuOpen} handleOpen={handleOpen}/>
      <HomeContact menuOpen={menuOpen} handleOpen={handleOpen}/>
    </>
  );
};

export default HomeIndex;
