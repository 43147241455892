import { createTheme, responsiveFontSizes } from "@mui/material";

export const theme = responsiveFontSizes(createTheme({
    palette: {
        primary: {
            // main: '#6C3C8C',
            main: '#195200'
        },
        fonts: {
            primary: '#343738',

        },
        background: {
            light: "rgba(255, 255, 255, 0.05)",
            dark: "#E6E5DF"
        },
    },
    typography: {
        fontFamily: ['Almarai'].join(' ')
    }
}));
