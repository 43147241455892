import {
  Box,
  Container,
  Dialog,
  Grow,
  IconButton,
  Typography,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";

import "./portfolio.scss";

import { projectList } from "../projectList";
import { useState, forwardRef } from "react";
import { Close } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const Portfolio = () => {
  const [open, setOpen] = useState({ open: false, src: "" });

  const handleClickOpen = (src, title) => {
    setOpen({ open: true, src: src, title: title });
  };

  const handleClose = () => {
    setOpen({ open: false, src: "", title: "" });
  };

  return (
    <Box
      id="projetos"
      className="portfolio"
      sx={{
        height: "100%",
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 0,
      }}
    >
      <Dialog
        open={open.open}
        TransitionComponent={Transition}
        onClose={handleClose}
        fullWidth
        
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            m: 2,
            color: "whitesmoke",
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        <img
          src={open.src}
          // style={{ width: "auto", height: "100%", maxHeight: "auto !important", objectFit:'scale-down' }}
          alt={open.title}
          style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 20,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography sx={{ color: "whitesmoke", opacity: "50%" }} variant="h5">
            {open.title}
          </Typography>
        </Box>
      </Dialog>

      <Container maxWidth="xl">
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              mb: 1.5,
            }}
          >
            <Typography variant="h1" fontWeight={400} color="fonts.primary">
              projetos
            </Typography>
          </Box>
        </Box>
      </Container>

      <Carousel
        sx={{ width: "100%", height: "100%" }}
        indicators={false}
        changeOnFirstRender={true}
        autoPlay={true}
      >
        {projectList.slice(0, projectList.length / 2).map((project) => (
          <Box
            sx={{
              width: "100%",
              height: "80vh",
              display: "flex",
              gap: 1.5,
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "25%",
                bgcolor: "white",
                display: { xs: "none", md: "block" },
              }}
            >
              <img
                src={require(`../../../assets/media/images/${project.images[0].path}`)}
                onClick={() =>
                  handleClickOpen(
                    require(`../../../assets/media/images/${project.images[0].path}`),
                    project.title
                  )
                }
                alt={project.title}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 1.5,
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{ width: "100%", display: "flex", gap: 1.5, height: "50%" }}
              >
                <Box sx={{ height: "100%", width: "30%" }}>
                  <img
                    src={require(`../../../assets/media/images/${project.images[1].path}`)}
                    alt={project.title}
                    onClick={() =>
                      handleClickOpen(
                        require(`../../../assets/media/images/${project.images[1].path}`),
                        project.title
                      )
                    }
                  />
                </Box>

                <Box sx={{ height: "100%", width: "70%" }}>
                  <img
                    src={require(`../../../assets/media/images/${project.images[2].path}`)}
                    alt={project.title}
                    onClick={() =>
                      handleClickOpen(
                        require(`../../../assets/media/images/${project.images[2].path}`),
                        project.title
                      )
                    }
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: 1.5,
                  height: "50%",
                  pb: { xs: 0, sm: 1.5 },
                }}
              >
                <Box sx={{ height: "100%", width: "70%" }}>
                  <img
                    src={require(`../../../assets/media/images/${project.images[3].path}`)}
                    alt={project.title}
                    onClick={() =>
                      handleClickOpen(
                        require(`../../../assets/media/images/${project.images[3].path}`),
                        project.title
                      )
                    }
                  />
                </Box>

                <Box sx={{ height: "100%", width: "30%" }}>
                  <img
                    src={require(`../../../assets/media/images/${project.images[4].path}`)}
                    alt={project.title}
                    onClick={() =>
                      handleClickOpen(
                        require(`../../../assets/media/images/${project.images[4].path}`),
                        project.title
                      )
                    }
                  />
                </Box>
              </Box>
              <Box
                sx={{ position: "absolute", bottom: 20, ml: 5, width: "100%" }}
              >
                <Typography
                  sx={{ color: "whitesmoke", opacity: "50%" }}
                  variant="h5"
                >
                  {project.title}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Carousel>

      <Carousel
        sx={{ width: "100%", height: "100%", mt: 3 }}
        indicators={false}
        changeOnFirstRender={true}
        autoPlay={true}
      >
        {projectList
          .slice(projectList.length / 2, projectList.length)
          .map((project) => (
            <Box
              sx={{
                width: "100%",
                height: "80vh",
                display: "flex",
                gap: 1.5,
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "25%",
                  bgcolor: "white",
                  display: { xs: "none", md: "block" },
                }}
              >
                <img
                  src={require(`../../../assets/media/images/${project.images[0].path}`)}
                  alt={project.title}
                  onClick={() =>
                    handleClickOpen(
                      require(`../../../assets/media/images/${project.images[0].path}`),
                      project.title
                    )
                  }
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: 1.5,
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: 1.5,
                    height: "50%",
                  }}
                >
                  <Box sx={{ height: "100%", width: "30%" }}>
                    <img
                      src={require(`../../../assets/media/images/${project.images[1].path}`)}
                      alt={project.title}
                      onClick={() =>
                        handleClickOpen(
                          require(`../../../assets/media/images/${project.images[1].path}`),
                          project.title
                        )
                      }
                    />
                  </Box>

                  <Box sx={{ height: "100%", width: "70%" }}>
                    <img
                      src={require(`../../../assets/media/images/${project.images[2].path}`)}
                      alt={project.title}
                      onClick={() =>
                        handleClickOpen(
                          require(`../../../assets/media/images/${project.images[2].path}`),
                          project.title
                        )
                      }
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: 1.5,
                    height: "50%",
                    pb: { xs: 0, sm: 1.5 },
                  }}
                >
                  <Box sx={{ height: "100%", width: "70%" }}>
                    <img
                      src={require(`../../../assets/media/images/${project.images[3].path}`)}
                      alt={project.title}
                      onClick={() =>
                        handleClickOpen(
                          require(`../../../assets/media/images/${project.images[3].path}`),
                          project.title
                        )
                      }
                    />
                  </Box>

                  <Box sx={{ height: "100%", width: "30%" }}>
                    <img
                      src={require(`../../../assets/media/images/${project.images[4].path}`)}
                      alt={project.title}
                      onClick={() =>
                        handleClickOpen(
                          require(`../../../assets/media/images/${project.images[4].path}`),
                          project.title
                        )
                      }
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 20,
                    ml: 5,
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{ color: "whitesmoke", opacity: "50%" }}
                    variant="h5"
                  >
                    {project.title}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
      </Carousel>
    </Box>
  );
};

export default Portfolio;
