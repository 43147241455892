import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import cep from "cep-promise";

import {
  cepMask,
  cnpjMask,
  cpfMask,
  phoneMask,
  rgMask,
} from "../assets/masks/masks";

const MyTextField = ({ inputs, register, errors, setValue }) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    let _values = { ...values };
    if (values.cnpj?.length >= 3) {
      _values.cnpj = cnpjMask(values.cnpj);
    }
    if (values.cpf?.length >= 3) {
      _values.cpf = cpfMask(values.cpf);
    }
    if (values.rg?.length >= 3) {
      _values.rg = rgMask(values.rg);
    }
    if (values.cellNumber?.length >= 3) {
      _values.cellNumber = phoneMask(values.cellNumber);
    }
    if (values.phoneNumber?.length >= 3) {
      _values.phoneNumber = phoneMask(values.phoneNumber);
    }
    setValues(_values);
  }, [
    values.cpf,
    values.rg,
    values.cellNumber,
    values.phoneNumber,
    values.cnpj,
  ]);

  useEffect(() => {
    if (values.cep?.length >= 5 && !values.street) {
      cep(values.cep)
        .then((address) => {
          setValues({
            ...values,
            ...address,
            cep: cepMask(address.cep),
            number: "",
            complement: "",
            reference: "",
          });
          setValue("city", address.city);
          setValue("street", address.street);
          setValue("neighborhood", address.neighborhood);
          setValue("state", address.state);
          setValue("cep", address.state);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [values.cep]);

  return (
    <Box component="form">
      <Grid container>
        {inputs.map((input) => (
          <Grid item xs={12} key={input.name}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {input.type === "select" ? (
                <FormControl
                  fullWidth
                  sx={{ mt: 1 }}
                  required={input.required}
                  error={errors[input.name]}
                  helperText={errors[input.name] && input.helperText}
                  {...register(input.name, { ...input })}
                  size="small"

                >
                  <InputLabel id="demo-simple-select-label">
                    {input.label}
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register(input.name, { ...input })}
                    value={values[input.name]}
                    onChange={(e) =>
                      setValues({ ...values, [input.name]: e.target.value })
                    }
                    label={input.label}
                  >
                    <MenuItem value="">
                      <em></em>
                    </MenuItem>
                    {input.options.map((option) => (
                      <MenuItem value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  sx={{ flex: 3 }}
                  margin="dense"
                  fullWidth
                  id={input.name}
                  label={input.label}
                  autoFocus
                  type={input.type}
                  size="small"
                  InputLabelProps={{
                    shrink: values[input.name]?.length >= 1 ? true : false,
                  }}
                  required={input.required}
                  error={errors[input.name]}
                  helperText={errors[input.name] && input.helperText}
                  {...register(input.name, { ...input })}
                  value={values[input.name]}
                  onChange={(e) =>
                    setValues({ ...values, [input.name]: e.target.value })
                  }
                />
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MyTextField;
