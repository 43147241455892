import {
  AppBar,
  Box,
  Container,
  IconButton,
  Link,
  MenuItem,
  Menu,
  MenuList,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";

import logoAssinatura from "../assets/images/studio_wafe_logo_principal.png";
import { Home, MenuOpen } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigate = (e) => {
    setAnchorEl(null);
    window.location.href = e;
  };

  const links = [
    { path: "/", label: "Home", icon: <Home /> },
    { path: "/#projetos", label: "Projetos" },
    { path: "/#contato", label: "Contato" },
    { path: "/portal", label: "Portal" },
  ];

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{ width: "100%", bgcolor: "background.dark" }}
    >
      <Container maxWidth="xl" sx={{ py: 3 }} position="fixed">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background: "transparent",
            height: { xs: 50, md: 70 },
            cursor: "pointer",
          }}
        >
          <img
            src={logoAssinatura}
            height="100%"
            alt="studio wafe - arquitetura e design de interiores"
            onClick={() => navigate("/")}
          />
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 2,
              alignItems: "center",
              textAlign: "right",
              color: "fonts.primary",
            }}
          >
            {links.map((link) => (
              <Link
                key={link}
                underline="hover"
                sx={{ color: "fonts.primary", cursor: "pointer" }}
                href={link.path}
              >
                {link.label}
              </Link>
            ))}
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" }, color: "white" }}>
            <IconButton sx={{ color: "fonts.primary" }} onClick={handleClick}>
              <MenuOpen />
            </IconButton>
          </Box>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {links.map((link, index) => (
              <MenuItem
                onClick={() => handleNavigate(link.path)}
                key={index}
                href={link.path}
                sx={{minWidth: 120}}
              >
                <ListItemText>{link.label}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Container>
    </AppBar>
  );
};

export default Navbar;
