import { Close } from "@mui/icons-material";
import { SwipeableDrawer, Box } from "@mui/material";

export default function Drawer({ state, handleClose, children }) {
  const toggleDrawer = () => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    handleClose();
  };

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={state}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        PaperProps={{
          sx: {
            // backgroundColor: "background.light",
            // color: "fonts.primary",
            height:'100%',
            mb:2
          }
        }}

      >
        <Box
          sx={{ width: { xs: window.innerWidth, md: 800 }, height:'100%', p: 2,  }}
        >
          {children}
        </Box>
      </SwipeableDrawer>
    </>
  );
}
