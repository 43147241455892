import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import { theme } from "./assets/theme";
import Navbar from "./components/Navbar";

function App() {
  return (
    <Box
      sx={{ minHeight: "100vh", bgcolor: "background.dark", height: "100%" }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />

        <Outlet />
      </ThemeProvider>
    </Box>
  );
}

export default App;
