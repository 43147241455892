import React, { useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import ClientRegistration from "./forms/ClientCreate";
import { useEffect } from "react";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../assets/configs/firebase.config";
import { getDocsFromFirestore } from "../../assets/calls/firebase.calls";

const Clients = () => {
  const [clientOpen, setClientOpen] = useState(false);

  const [clients, setClients] = useState([]);

  useEffect(() => {
    // const _clients = [];
    // getDocs(collection(db, "clients")).then((querySnapshot) => {
    //   querySnapshot.forEach((doc) => {
    //     _clients.push({ id: doc.id, ...doc.data() });
    //     setClients(_clients);
    //   });
    // });

    getDocsFromFirestore('clients').then(clients => setClients(clients))
  }, [clientOpen]);

  return (
    <Grid
      item
      xs={12}
      md={3.9}
      component={Paper}
      p={1}
      bgcolor="background.dark"
      sx={{ height: { xs: "6vh", md: "65vh" } }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" color="fonts.primary">
          clientes
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            color: "fonts.primary",
          }}
        >
          <IconButton
            sx={{
              bgcolor: "primary.main",
              color: "rgba(255, 255, 255, 0.45)",
              fontWeight: 600,
            }}
            onClick={() => setClientOpen(true)}
          >
            <Add />
          </IconButton>

          <ClientRegistration open={clientOpen} setOpen={setClientOpen} />
          <IconButton
            sx={{
              bgcolor: "primary.main",
              color: "rgba(255, 255, 255, 0.45)",
              fontWeight: 600,
            }}
          >
            <Search />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "inline" },
          width: "100%",
          height: "100%",
          overflow: "auto",
          maxHeight: "60vh",
          pr: 1,
        }}
      >
        <MenuList dense="dense">
          {clients.map((client) => (
            <Box
              bgcolor="background.dark"
              sx={{ width: "100%", borderRadius: "10px" }}
            >
              <MenuItem>
                <ListItemAvatar>
                  <Avatar
                    src="https://www.figma.com/file/YaDHCDXT7VxqQdSQR6mkyg/Untitled?type=design&node-id=11-546&mode=design&t=n9FRqokNiPMGmY7z-4"
                    width={33}
                    height={33}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant="subtitle1" color="fonts.primary">
                    {client.name}
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Box>
          ))}
        </MenuList>
      </Box>
    </Grid>
  );
};

export default Clients;
