import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import React from "react";

const Projects = () => {
  return (
    <Grid
      item
      xs={12}
      md={7.9}
      component={Paper}
      p={1}
      bgcolor="background.dark"
      sx={{ height: { xs: "6vh", md: "65vh" } }}
    >
      <Box sx={{ }}>
        {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
  
    <Tab label="Item One" {...a11yProps(0)} />
    <Tab label="Item Two" {...a11yProps(1)} />
    <Tab label="Item Three" {...a11yProps(2)} />
  </Tabs> */}

        <Tabs
          centered
          variant="scrollable"
          sx={{color:'rgba(255, 255, 255, 0.45)'}}
          textColor="rgba(255, 255, 255, 0.45)"
          indicatorColor="primary"
          value={0}
        >
          <Tab label="estudos preliminares" active value={0}/>
          <Tab label="anteprojeto" />
          <Tab label="executivo" />
          <Tab label="obra" />
          <Tab label="financeiro" />
          <Tab label="cadastro" />
        </Tabs>
      </Box>
    </Grid>
  );
};

export default Projects;
